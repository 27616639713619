import { DatePipe } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import * as moment from 'moment'
import { ToastrService } from 'ngx-toastr'
import { ConfirmationService, MessageService } from 'primeng/api'
import { DividerModule } from 'primeng/divider'
import { DialogService } from 'primeng/dynamicdialog'
import { PanelModule } from 'primeng/panel'
import { LocalUser } from 'src/app/shared/models/local_user'
import { PublicadorDTO } from 'src/app/shared/models/publicador.dto'
import { AgendamentoService } from 'src/app/shared/services/agendamento.service'
import { AuthService } from 'src/app/shared/services/auth.service'
import { MESES } from 'src/app/shared/utils/constantes'
import { ConstantesMensagens } from 'src/app/shared/utils/constantesMensagens'
import { AgendamentoDto } from '../../../shared/models/agendamento-dto'
import { PerfilPublicadorService } from '../../administracao/pages/service/perfil-publicador.service'

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    providers: [DialogService, MessageService, DividerModule, PanelModule],
})
export class HomeComponent implements OnInit {
    private datepipe: DatePipe = new DatePipe('en-US')
    localUser: LocalUser
    public nav: any
    listaAgendamentos: any[] = []
    listaAgendamentosFilter: any[] = []
    listProgramacao: any[] = []
    listProgramacaoFilter: any[] = []
    agendamentoDto = new AgendamentoDto()
    minDateValue: Date
    valueData
    valueDataSemPar
    indiceMinhaProgramacao: number = 0
    modalDetalhesProgramacao: boolean = false
    detalhesPonto: any
    publicador: PublicadorDTO
    nomeUsuario
    ativarAgendamentos: boolean = false
    ativarMinhaProgramacao: boolean = false
    activeState: boolean[] = [true, false, false]
    mensagemCabecalho = ConstantesMensagens.MENSAGEM_DESCRICAO_HOME
    mensagem: string = ''
    responsiveOptions
    injector: any
    checked: boolean = false
    aviso =
        'AO USAR ESTE SITE E NOS FORNECER DADOS PESSOAIS, VOCÊ DÁ SEU CONSENTIMENTO EXPLÍCITO PARA QUE TODOS OS DADOS ENVIADOS SEJAM PROCESSADOS POR NÓS DA MANEIRA E PARA AS FINALIDADES A QUE SE DESTINAM, OU SEJA, A ORGANIZAÇÃO E OPERACIONALIZAÇÃO DO TESTEMUNHO PÚBLICO ESPECIAL EM REGIÕES METROPOLITANAS (TPE), RESGUARDADO O DIREITO À PRIVACIDADE NOS TERMOS DA LEGISLAÇÃO EM VIGOR.'
    avisoExtra = ''

    public constructor(
        public authService: AuthService,
        public router: Router,
        public dialogService: DialogService,
        public messageService: MessageService,
        private agendamentoService: AgendamentoService,
        private confirmationService: ConfirmationService,
        private perfilPublicadorService: PerfilPublicadorService
    ) {
        this.nav = document.querySelector('nav.navbar')
        this.responsiveOptions = [
            {
                breakpoint: '1024px',
                numVisible: 3,
                numScroll: 3,
            },
            {
                breakpoint: '768px',
                numVisible: 2,
                numScroll: 2,
            },
            {
                breakpoint: '560px',
                numVisible: 1,
                numScroll: 1,
            },
        ]
    }

    ngOnInit() {
        this.buscarPublicador()
        this.buscarAgendamentosSemPar()
        this.activeState[1] = !this.activeState[1]
    }

    private get _toastrService(): ToastrService {
        return this.injector.get(ToastrService)
    }

    buscarPublicador() {
        this.perfilPublicadorService.getPublicadorLogado().subscribe((response: any) => {
            this.publicador = response[0]
            this.buscarProgramacao()
            var arrayNome = this.publicador.nome.split(' ')
            this.nomeUsuario = arrayNome[0].toUpperCase()
        })
    }

    buscarAgendamentosSemPar() {
        const hoje = moment()
        const dataFormatada = moment(hoje, 'DD/MM/YYYY')
        const dataAdd8Dias = moment().add(8, 'days')
        const dataAdd2Dias = hoje.add(2, 'days')
        this.agendamentoService
            .getAgendamentoSemPar(this.datePipeFormat(dataAdd2Dias), this.datePipeFormat(dataAdd8Dias))
            .subscribe((response: any) => {
                this.listaAgendamentos = response
                this.listaAgendamentosFilter = response
                if (this.listaAgendamentosFilter.length > 0) {
                    this.ativarAgendamentos = true
                }
            })
    }

    filtrarAgendamentosSemPar() {
        this.ativarAgendamentos = false
        this.agendamentoService
            .getAgendamentoSemPar(this.datePipeFormat(this.valueDataSemPar), this.datePipeFormat(this.valueDataSemPar))
            .subscribe((response: any) => {
                this.listaAgendamentosFilter = response
                if (this.listaAgendamentosFilter.length > 0) {
                    this.ativarAgendamentos = true
                }
            })
    }

    buscarProgramacao() {
        const hoje = moment()
        const dataFormatada = moment(hoje, 'DD/MM/YYYY')
        const dataAdd7Dias = hoje.add(7, 'day')
        this.agendamentoService
            .getAgendamentoPublicador(this.publicador.uuid, this.datePipeFormat(dataFormatada), this.datePipeFormat(dataAdd7Dias))
            .subscribe((response: any) => {
                this.listProgramacao = response
                this.listProgramacaoFilter = response
                if (this.listProgramacaoFilter.length > 0) {
                    this.ativarMinhaProgramacao = true
                }
            })
    }

    filtrarProgramacao() {
        this.ativarMinhaProgramacao = false
        this.agendamentoService
            .getAgendamentoPublicador(this.publicador.uuid, this.datePipeFormat(this.valueData), null)
            .subscribe((response: any) => {
                this.listProgramacaoFilter = response
                if (this.listProgramacaoFilter.length > 0) {
                    this.ativarMinhaProgramacao = true
                }
            })
    }

    confirm(event: Event, agendamento) {
        this.mensagem = ''

        this.confirmationService.confirm({
            target: event.target,
            message:
                'O agendamento na data ' +
                agendamento.data +
                ' de ' +
                agendamento.horainicio +
                'h' +
                ' às ' +
                agendamento.horafim +
                'h' +
                ' será cancelado. ' +
                ' Cancelar:',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Apenas meu Agendamento',
            rejectLabel: 'Meu e do meu par',
            accept: () => {
                if (!agendamento.auxiliar) {
                    this.agendamentoService.deletarAgendamento(agendamento.uuid).subscribe((retorno) => {
                        this.mensagem = 'Seu Agendamento foi cancelado com sucesso!'
                        this.updatePage(this.mensagem)
                    })
                    return
                }

                this.agendamentoService.atualizaAgendamento(this.incluirAgendamento(agendamento, 'cancelar')).subscribe((retorno) => {
                    this.mensagem = 'Seu Agendamento foi cancelado com sucesso!'
                    this.updatePage(this.mensagem)
                })
            },
            reject: () => {
                if (!agendamento.auxiliar) {
                    this.agendamentoService.deletarAgendamento(agendamento.uuid).subscribe((retorno) => {
                        this.mensagem = 'Seu Agendamento foi cancelado com sucesso!'
                        this.updatePage(this.mensagem)
                    })
                    return
                }

                this.agendamentoService.deletarAgendamento(agendamento.uuid).subscribe((retorno) => {
                    this.mensagem = 'Seu Agendamento e o do irmã(o) ' + agendamento.auxiliar?.nome + ' foi cancelado com sucesso!!'
                    this.updatePage(this.mensagem)
                })
            },
        })
    }

    confirmAgendamento(event: Event, agendamento) {
        this.mensagem = ''

        if (agendamento.principal.uuid === this.publicador.uuid) {
            this.messageService.add({
                severity: 'warn',
                summary: 'Alerta',
                detail: 'Esse agendamento já é seu, por gentileza, aguarde um par!',
                life: 3000,
            })
            return
        }

        if (agendamento.ponto.eventoAtivo && !this.publicador.eventosAtivos) {
            this.messageService.add({
                severity: 'warn',
                summary: 'Alerta',
                detail: 'Você não está cadastrado para participar desse evento!',
                life: 3000,
            })
            return
        }

        this.confirmationService.confirm({
            target: event.target,
            message: 'Confirma o agendamento: ' + agendamento.data + ' de ' + agendamento.horainicio + 'h' + ' às ' + agendamento.horafim + 'h' + '?',
            icon: 'pi pi pi-calendar',
            acceptLabel: 'Sim',
            rejectLabel: 'Não',
            accept: () => {
                this.agendamentoService.atualizaAgendamento(this.incluirAgendamento(agendamento, 'atualizar')).subscribe((retorno) => {
                    this.mensagem = 'Agendamento realizado com sucesso!'
                    this.updatePage(this.mensagem)
                })
            },
            reject: () => {
                close()
            },
        })
    }

    incluirAgendamento(agendamento, acao): AgendamentoDto {
        this.agendamentoDto = new AgendamentoDto()

        this.agendamentoDto.uuid = agendamento.uuid
        this.agendamentoDto.principaluuid = agendamento.principal.uuid
        this.agendamentoDto.publicadoruuid = agendamento.principal.uuid
        this.agendamentoDto.auxiliaruuid = this.publicador.uuid

        if (acao === 'cancelar') {
            if (this.publicador.uuid === this.agendamentoDto.principaluuid) {
                this.agendamentoDto.principaluuid = agendamento.auxiliar.uuid
                this.agendamentoDto.publicadoruuid = agendamento.auxiliar.uuid
            }

            this.agendamentoDto.auxiliaruuid = null
        }

        this.agendamentoDto.horainicio = agendamento.horainicio
        this.agendamentoDto.horafim = agendamento.horafim
        this.agendamentoDto.data = agendamento.data
        this.agendamentoDto.pontouuid = agendamento.ponto.uuid

        return this.agendamentoDto
    }

    checarIndiceMinhaProgramacao(event) {
        this.indiceMinhaProgramacao = event.page
    }

    abrirModalDetalhesProgramacao(ponto: any) {
        this.detalhesPonto = ponto
        this.agendamentoService.recuperaAgendamentos(ponto.ponto.uuid, ponto.data).subscribe((response) => {
            this.detalhesPonto.agendamentos = response
            this.detalhesPonto.agendamentos = this.detalhesPonto?.agendamentos?.sort(function (a, b) {
                return a.horainicio - b.horainicio
            })
        })
        this.modalDetalhesProgramacao = true
    }

    extrairDia(data): any {
        const dataEmPartes = data.split('/')
        return dataEmPartes[0]
    }

    extrairMes(data): any {
        const dataEmPartes = data.split('/')
        return MESES[+dataEmPartes[1] - 1].value
    }

    updatePage(mensagem) {
        this.buscarAgendamentosSemPar()
        this.buscarProgramacao()
        this.messageService.add({
            severity: 'success',
            summary: 'Confirmado',
            detail: mensagem,
        })
    }

    limparFiltroProgramacao() {
        this.listProgramacaoFilter = []
        this.listProgramacaoFilter = this.listProgramacao
        this.valueData = null
    }

    limparFiltroAgendamentosSemPar() {
        this.listaAgendamentosFilter = []
        this.listaAgendamentosFilter = this.listaAgendamentos
        this.valueDataSemPar = null
    }

    redirect() {
        this.router.navigate(['/agendamentos/agendar'])
    }

    private datePipeFormat(date: any) {
        return this.datepipe.transform(new Date(date), 'dd/MM/yyyy')
    }
}
