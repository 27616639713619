import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Regiao } from 'src/app/modules/agendamento/models/regiao';
import { environment } from 'src/environments/environment';
import { AgendamentoDto } from '../models/agendamento-dto';

@Injectable({
  providedIn: 'root'
})
export class AgendamentoService {

  private options = {
    headers: new HttpHeaders().set('Content-Type', 'application/json'),
  };


  constructor(private http: HttpClient) { }

  getPontosRegiao(regiao: string): Observable<any[]> {
    return this.http.get<any[]>(environment.serverUrl + 'ponto/regiao/' + regiao, this.options)
  }

  getPublicadorAuxiliar(nome: string): Observable<any> {
    const url = `${environment.serverUrl}publicador/nome/${nome}`
    return this.http.get<any[]>(url, this.options)
  }

  criarNovoAgendamento(agendamentoDto: AgendamentoDto): Observable<any> {
    return this.http.post(environment.serverUrl + 'agendamento', agendamentoDto, this.options)
  }
  
  atualizaAgendamento(agendamentoDto: AgendamentoDto): Observable<any> {     
    return this.http.put(environment.serverUrl + 'agendamento/' + agendamentoDto.uuid, agendamentoDto, this.options)
  }

  apagaAgendamento(uuid: string): Observable<any> {     
    return this.http.delete(environment.serverUrl + 'agendamento/' + uuid, this.options)
  }

  recuperaAgendamentos(pontoUuid : string, data : string){
    return this.http.get<any[]>(environment.serverUrl + 'agendamento/ponto/' + pontoUuid + "?data=" + data, this.options);
  }

  getAgendamentoPublicador(publicadoruuid: string, data: string, dataFim: string): Observable<any[]> {
    const url = `${environment.serverUrl}agendamento/publicador/${publicadoruuid}`
    if (dataFim) {     
      return this.http.get<any[]>(url + "?data=" + data + "&datafim=" + dataFim, this.options)
    }       
    return this.http.get<any[]>(url + "?data=" + data, this.options)
  }

  getAgendamentoSemPar(dataInicial: string, dataFim: string): Observable<any[]> {
    return this.http.get<any[]>(environment.serverUrl + 'agendamento?datainicio=' + dataInicial + "&datafim=" + dataFim + "&aguardandopar=" + true, this.options);
  }

  getAgendamentoPorDatas(dataInicial: string, dataFim: string): Observable<any[]> {
    return this.http.get<any[]>(environment.serverUrl + 'agendamento?datainicio=' + dataInicial + "&datafim=" + dataFim + "&aguardandopar=" + false, this.options);
  }

  getEvento(): Observable<any> {
    return this.http.get<any>(environment.serverUrl + 'evento/ativos', this.options);
  }

  deletarAgendamento(uuid: string): Observable<any> {
    const url = `${environment.serverUrl}agendamento/${uuid}`
    return this.http.delete<any>(url, this.options);
  }
}