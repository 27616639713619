import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { CadastroCongregacaoComponent } from './modules/administracao/pages/cadastro-congregacao/cadastro-congregacao.component'
import { HomeComponent } from './modules/core/home/home.component'
import { LoginComponent } from './modules/core/login/login.component'
import { PerfilPublicadorComponent } from './modules/core/perfil-publicador/perfil-publicador.component'
import { TemplateComponent } from './modules/core/template/template.component'
import { UnauthorizedComponent } from './modules/core/unauthorized/unauthorized.component'
import { AuthGuardService } from './shared/services/auth-guard.service'

const routes: Routes = [
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: 'unauthorized', component: UnauthorizedComponent },
    { path: 'login', component: LoginComponent },
    {
        path: '',
        canActivate: [AuthGuardService],
        component: TemplateComponent,
        children: [
            { path: 'home', component: HomeComponent},
            { path: 'perfil-publicador', component: PerfilPublicadorComponent},
            {
                path: 'gestores',
                loadChildren: () => import('./modules/gestores/gestores.module').then((m) => m.GestoresModule),
            },
            {
                path: 'relatorios',
                loadChildren: () => import('./modules/relatorios/relatorios.module').then((m) => m.RelatoriosModule),
            },
            {
                path: 'administracao',
                loadChildren: () => import('./modules/administracao/administracao.module').then((m) => m.AdministracaoModule),
            },
            {
                path: 'agendamentos',
                loadChildren: () => import('./modules/agendamento/agendamento.module').then((m) => m.AgendamentoModule),
            }
        ],
    },
]

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
