<div *ngIf="!isEsqueciSenha"
     class="flex justify-content-center flex-wrap card-container" id="wrapper">       
    <div id="login">
        <form class="card">
            <div class="card-header  mb-4">
                <img src="assets/images/tpe.png" class="tpe_inicio">
            </div>
            <div class="card-body">
                <label for="usuario" class="text-white mb-2 text-lg">Login</label>
                <div class="alert alert-danger" *ngFor="let erro of errors">
                    {{ erro }}
                </div>
                <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <input type="text" formControlName="username" class="form-control" placeholder="Usuário"
                            [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                        <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                            <div *ngIf="f.username.errors.required" class="text-white">Informar o usuário é obrigatório</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <input type="password" formControlName="password" class="form-control" placeholder="senha"
                            [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                            <div *ngIf="f.password.errors.required" class="text-white">Informar uma senha é obrigatório</div>
                        </div>
                    </div>
                    <div class="flex justify-content-center">
                        <button pButton pRipple type="submit" label="Entrar" class="p-button-raised p-button-secondary font-bold bg-blue-900 mb-2"></button>
                    </div>
                    <div class="flex justify-content-center">
                        <a (click)="esqueciminhasenha()" class="text-white recuperar_senha">Esqueceu a senha?</a>
                    </div>
                    <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
                </form>
            </div>
        </form>
    </div>
</div>
<div *ngIf="isEsqueciSenha"
     class="flex justify-content-center flex-wrap card-container" id="wrapper">
    <div id="login">
        <form class="card">
            <div class="card-header  mb-4">
                <img src="assets/images/tpe.png" class="tpe_inicio">
            </div>
            <div class="card-body">
                <label for="usuario" class="text-white mb-2 text-lg">Digite seu email</label>
                <div class="alert alert-danger" *ngFor="let erro of errors">
                    {{ erro }}
                </div>
                <form [formGroup]="loginForm" (ngSubmit)="submitEsqueciSenha()">
                    <div class="form-group">
                        <input type="text" formControlName="email" class="form-control" placeholder="Email"
                            [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                            <div *ngIf="f.email.errors.required" class="text-white">Informar o email é obrigatório</div>
                        </div>
                    </div>
                    
                    <div class="flex justify-content-center">
                        <button pButton pRipple type="submit" label="Enviar" class="p-button-raised p-button-secondary font-bold bg-blue-900 mb-2"></button>
                    </div>
                    <div class="flex justify-content-center">
                        <a href="#" class="text-white">Voltar</a>
                    </div>
                    <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
                </form>
            </div>
        </form>
    </div>
</div>
<p-toast></p-toast>