import { MenuItem } from 'primeng/api'
import { AuthService } from 'src/app/shared/services/auth.service'
import { Router } from '@angular/router'
import { Component, OnInit } from '@angular/core'

@Component({
    selector: 'app-template',
    templateUrl: './template.component.html',
    styleUrls: ['./template.component.css'],
})
export class TemplateComponent implements OnInit {
    nomeUsuario: string
    _isAuthenticated = false
    menus: MenuItem[];   
    user

    pageSelect = JSON.parse(localStorage.getItem('tela'));  
    telaHome: boolean = false;
    telaCadastrar: boolean = false;
    mensagemCabecalho: string;

    constructor(public router: Router, private oidcAuthService: AuthService) { }    

    ngOnInit(): void {
        this.user = this.oidcAuthService.getLoggedUser();                
        this.initMenuPrincipal();   
        this.montaIniciaisNome();    
    }    

    get isAutenticated() {
        return this._isAuthenticated
    }

    goToHome() {
        this.router.navigate(['/'])
    }

    logout() {
        this.oidcAuthService.logout();
        this.router.navigate(['/unauthorized'])
    }

    montaIniciaisNome() {
        var arrayNome= this.user.nome.split(' ');
        this.nomeUsuario = arrayNome[0].substr(0, 1).toUpperCase();
        this.nomeUsuario = this.nomeUsuario + arrayNome[1].substr(0, 1).toUpperCase();
    }

    initMenuPrincipal() {
       
        let isAdmin = (this.user.perfil.nome == 'ADMIN' || this.user.perfil.nome == 'ADMINPONTO');
        this.menus = [
            {
                label: 'Início',
                routerLink: ['/home'],
                icon: 'pi pi-home',                
            },
            { label: 'Agendamento', routerLink: ['/agendamentos/'] },
            //{ label: 'Eventos', routerLink: ['/eventos'], visible: isAdmin },            
            { label: 'Administração', routerLink: ['/administracao'], visible: isAdmin },
            { label: 'Relatórios', routerLink: ['/relatorios'], visible: isAdmin },
            //{ label: 'Pesquisar', routerLink: ['/pesquisar'], disabled: true }
        ]
    }    
}
