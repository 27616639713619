<div class="grid justify-content-center align-items-center">
    <div class="col-12 p-0 m-0 surface-400">
        <label class="text-xl text-900 ml-3 p-2 font-bold p-0 m-0 flex justify-content-start font-light pt-2">{{
            mensagemCabecalho }}, {{ nomeUsuario }}</label>
    </div>
    <div *ngIf="false" class="field-12 p-0 m-0">
        <div class="border-1 p-0 m-1 border-red-400">
            <div class="col-12 flex justify-content-center p-0 m-0 mb-0">
                <label class="text-xl p-0 m-0 font-bold  text-900 font-light">Avisos</label>
            </div>
            <div class="col-12 m-0 pb-0 pt-0 flex justify-content-center">
                <label class="p-0 m-0 font-bold text-blue-900 font-italic text-base font-light"><span
                        class="font-bold text-red-600">IMPORTANTE: </span>Sistema temporariamente indisponível para agendamentos! Previsão de retorno na Segunda-Feira.                   
                </label>
            </div>
        </div>
    </div>
    <p-fieldset *ngIf="true" legend="IRMÃOS SEM COMPANHIA" class="pt-1 w-11" [toggleable]="true" [collapsed]="true">
        <div *ngIf="!ativarAgendamentos">
            <label class="text-lg p-0 m-0 text-900 font-light pt-2">Não há agendamentos sem par.</label>
        </div>
        <p-carousel *ngIf="ativarAgendamentos" class="p-0 m-0" [value]="listaAgendamentosFilter"
            [responsiveOptions]="responsiveOptions" [numVisible]="4" [numScroll]="3" [circular]="false">
            <ng-template let-agendamento pTemplate="item">
                <div class="card agendamento-item">
                    <div class="agendamento-item-content">
                        <h2 class="pl-3 pt-1 p-0 m-0 mb-3 font-bold text-white">
                            {{ extrairDia(agendamento.data) }} de {{ extrairMes(agendamento.data) }}
                        </h2>
                        <h3 *ngIf="agendamento.ponto?.eventoAtivo" class="pl-3 text-sm text-white font-bold">
                            <span style="color: lawngreen">EVENTO</span>: {{ agendamento.ponto?.eventoAtivo?.nome }}
                        </h3>
                        <h4 class="pl-3 mb-0 text-sm text-white">Horário: {{ agendamento.horainicio }}h às {{
                            agendamento.horafim }}h</h4>
                        <h4 class="pl-3 pt-1 mb-0 text-sm text-white">Ponto: {{ agendamento.ponto.nome }}</h4>
                        <!--<h4 class="pl-3 pt-1 mb-0 text-sm text-white">Endereço: {{ agendamento.ponto.endereco }}</h4>-->
                        <h4 class="pl-3 pt-1 mt-2 text-sm text-white">{{ agendamento.principal.nome }}</h4>
                        <div class="field p-2 m-0 footer-fields">
                            <button pButton pRipple type="button" label="Agendar" icon="pi pi-calendar"
                                class="p-button-raised bg-bluegray-800 mr-1 border-800 text-xs text-white p-button-rounded text-900"
                                (click)="confirmAgendamento($event, agendamento)" styleClass="p-button-sm"></button>
                            <button pButton pRipple type="button" label="Detalhes" icon="pi pi-book"
                                class="p-button-raised bg-bluegray-800 mr-1 border-800 text-xs text-white p-button-rounded text-900"
                                (click)="abrirModalDetalhesProgramacao(agendamento)" styleClass="p-button-sm"></button>
                        </div>
                    </div>
                </div>
            </ng-template>
        </p-carousel>
        <p-toolbar styleClass="col-12 p-2 p-0 m-0 flex justify-content-center border-none">
            <p-calendar [(ngModel)]="valueDataSemPar" styleClass="max-w-13rem mr-3 h-2rem" [touchUI]="true"
                [readonlyInput]="true" inputId="touchui" (click)="filtrarAgendamentosSemPar()" dateFormat="dd/mm/yy"
                placeholder="Clique e filtre pela data"></p-calendar>
            <button pButton pRipple type="button" icon="pi pi-trash" pTooltip="Limpar Filtro" tooltipPosition="top"
                class="p-button-raised mr-2 bg-blue-900 border-800 text-white p-button-rounded text-900 font-bold"
                (click)="limparFiltroAgendamentosSemPar()"></button>
        </p-toolbar>
    </p-fieldset>
    <p-fieldset legend="MINHA PROGRAMAÇÃO" class="pt-2 w-11">
        <label class="text-sm p-0 m-0 ml-2 mb-2 text-900 font-light">* Programação dos próximos 07 dias.</label>
        <div *ngIf="!ativarMinhaProgramacao">
            <label class="text-lg p-0 m-0 text-900 font-light pt-2">Não há programação. Agende seu trabalho na tela de
                agendamento.</label>
        </div>
        <p-carousel *ngIf="ativarMinhaProgramacao" class="p-0 m-0" [value]="listProgramacaoFilter"
            (onPage)="checarIndiceMinhaProgramacao($event)" [responsiveOptions]="responsiveOptions" [numVisible]="3"
            [circular]="false" [numScroll]="3">
            <ng-template let-agendamento pTemplate="item">
                <div class="card agendamento-item meu-agendamento-wrapper">
                    <div>
                        <div class="header-icon pt-4 mb-3">
                            <i class="ml-4 pi pi-calendar calendar-icon" style="font-size: 2rem"></i>
                            <h2 class="ml-2 p-0 m-0 font-bold">{{ extrairDia(agendamento.data) }} {{
                                extrairMes(agendamento.data) }}</h2>
                        </div>
                        <h4 *ngIf="agendamento.ponto?.eventoAtivo" class="ml-3 mb-2 p-0 m-0 font-bold">
                            <span style="color: #4caf50" class="font-bold">EVENTO</span>:
                            <span class="font-bold">{{ agendamento.ponto?.eventoAtivo?.nome }}</span>
                        </h4>
                        <div class="field">
                            <h3 class="ml-3 mb-0 m-0">
                                Horário: <span class="font-bold">{{ agendamento.horainicio }}h às {{ agendamento.horafim
                                    }}h</span>
                            </h3>
                            <h3 class="ml-3 mb-0 pt-2 m-0">
                                Ponto: <span class="font-bold">{{ agendamento.ponto.nome }} </span>
                            </h3>
                            <h4 class="ml-3 mb-0 pt-2 m-0">
                                Endereço: <span class="font-bold">{{ agendamento.ponto.endereco }}</span>
                            </h4>
                        </div>
                    </div>
                    <div class="field p-2 m-0 footer-fields-meu-agendamento">
                        <button pButton pRipple type="button" label="Cancelar" icon="pi pi-times"
                            styleClass="p-button-sm"
                            class="p-button-raised bg-blue-900 pl-2 border-800 text-xs text-white p-button-rounded text-900"
                            (click)="confirm($event, agendamento)"></button>
                        <button pButton pRipple type="button" label="Detalhes" icon="pi pi-search-plus"
                            class="p-button-raised bg-blue-900 pl-2 border-800 text-xs text-white p-button-rounded text-900"
                            (click)="abrirModalDetalhesProgramacao(agendamento)" styleClass="p-button-sm"></button>
                    </div>
                </div>
            </ng-template>
        </p-carousel>
        <p-toolbar styleClass="col-12 p-2 p-0 m-0 flex justify-content-center border-none">
            <!--<label class="text-sm p-0 m-0 mr-1 ext-900 font-light">Apenas evento</label>
            <p-inputSwitch class="mr-2 mt-2" [(ngModel)]="checked"></p-inputSwitch>-->
            <p-calendar [(ngModel)]="valueData" styleClass="max-w-13rem mr-3 h-2rem" [touchUI]="true"
                [readonlyInput]="true" inputId="touchui" (click)="filtrarProgramacao()" dateFormat="dd/mm/yy"
                placeholder="Clique e filtre pela data"></p-calendar>
            <button pButton pRipple type="button" icon="pi pi-trash" pTooltip="Limpar Filtro" tooltipPosition="top"
                class="p-button-raised mr-2 bg-blue-900 border-800 text-white p-button-rounded text-900 font-bold"
                (click)="limparFiltroProgramacao()"></button>
            <button pButton pRipple type="button" icon="pi pi-calendar" pTooltip="Tela de Agendamentos"
                tooltipPosition="top"
                class="p-button-raised bg-blue-900 border-800 text-white p-button-rounded text-900 font-bold"
                (click)="redirect()"></button>
        </p-toolbar>
    </p-fieldset>
</div>
<p-toast></p-toast>
<p-confirmPopup></p-confirmPopup>
<p-dialog header="Detalhes do dia {{ detalhesPonto?.data }}" [(visible)]="modalDetalhesProgramacao" modal="true">
    <div class="col-12">
        <p-panel header="DETALHES DO PONTO">
            <div class="flex flex-column mt-1">
                <span class="p-column-title">Endereço do Ponto: {{ detalhesPonto?.ponto?.endereco }} -
                    <a href="http://maps.google.com/maps?q={{ detalhesPonto?.ponto?.latitude }},{{ detalhesPonto?.ponto?.longitude }}&ll={{
                            detalhesPonto?.ponto?.latitude
                        }},{{ detalhesPonto?.ponto?.longitude }}&z=20" target="_blank">
                        Ver no Mapa</a></span>
                <span class="p-column-title">Endereço do Deposito: {{ detalhesPonto?.ponto?.enderecodeposito }} -
                    <a href="http://maps.google.com/maps?q={{ detalhesPonto?.ponto?.latitudedeposito }},{{
                            detalhesPonto?.ponto?.longitudedeposito
                        }}&ll={{ detalhesPonto?.ponto?.latitudedeposito }},{{ detalhesPonto?.ponto?.longitudedeposito }}&z=20"
                        target="_blank">Ver no Mapa</a></span>
                <span class="p-column-title">Responsavel: {{ detalhesPonto?.ponto?.responsavel.nome }}</span>
                <span class="p-column-title">Telefone do Responsavel: {{ detalhesPonto?.ponto?.responsavel.telefone
                    }}</span>
                <span class="p-column-title">Auxiliar do Ponto: {{ detalhesPonto?.ponto?.auxiliar.nome }}</span>
                <span class="p-column-title">Telefone do Auxiliar: {{ detalhesPonto?.ponto?.auxiliar.telefone }}</span>
            </div>
        </p-panel>
        <p-divider></p-divider>
        <p-panel header="Todos os agendamentos neste dia">
            <p-table [value]="detalhesPonto?.agendamentos" styleClass="p-datatable-gridlines" responsiveLayout="scroll">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Publicadores</th>
                        <th>Contato</th>
                        <th>Horários</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-agendamento>
                    <tr>
                        <td class="p-2">
                            {{ agendamento?.principal?.nome }} <span *ngIf="agendamento?.auxiliar">/</span><br />{{
                            agendamento?.auxiliar?.nome }}
                        </td>
                        <td class="p-2">
                            <a href="https://wa.me/55{{ agendamento.principal.telefone }}" target="_blank">{{
                                agendamento?.principal?.telefone }}</a>
                            <span *ngIf="agendamento?.auxiliar">|</span><br /><a
                                href="https://wa.me/55{{ agendamento.ponto.auxiliar.telefone }}" target="_blank">{{
                                agendamento?.auxiliar?.telefone }}</a>
                        </td>
                        <td class="p-2">{{ agendamento.horainicio }}h-{{ agendamento.horafim }}h</td>
                    </tr>
                </ng-template>
            </p-table>
        </p-panel>
    </div>
</p-dialog>